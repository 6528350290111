import { useParams } from 'react-router-dom';
import { findIana } from 'windows-iana';
import { add, differenceInMinutes, format } from 'date-fns';
import { getTimezoneOffset, toDate } from 'date-fns-tz';
import { useAvailabilities, useUnavailabilities } from 'hooks';
import {
  DayOfWeek,
  EProgramVariation,
  IAvailability,
  TimeZone,
} from '@guider-global/shared-types';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useProfiles,
  useRelationships,
  useSettings,
} from '@guider-global/front-end-hooks';

interface UseDateTimeSlotsProps {
  now: Date;
  currentDate: Date;
  currentStartTime: string;
}

const SLOT_MINUTES = 15;
const SLOT_DURATION = { minutes: SLOT_MINUTES };

export function useDateTimeSlots({
  now,
  currentDate,
  currentStartTime,
}: UseDateTimeSlotsProps) {
  const organizationSlug = getSubDomain();
  const { relationshipId } = useParams();

  const { profile, isLoadingProfiles } = useProfiles({});

  const { relationships, isLoadingRelationships } = useRelationships({});
  const relationship = relationships?.find(
    (relationship) => relationship.id === relationshipId,
  );

  const guideProfiles = relationship?.guideProfiles;
  const traineeProfiles = relationship?.traineeProfiles;

  const guideProfilesIds =
    guideProfiles?.map((guideProfile) => guideProfile?.id) ?? [];
  const traineeProfilesIds =
    traineeProfiles?.map((traineeProfile) => traineeProfile?.id) ?? [];

  const isGuide = guideProfilesIds.includes(profile?.id);
  const isTrainee = traineeProfilesIds.includes(profile?.id);

  const isGroupProgram =
    relationship?.programVariationTypeSlug === EProgramVariation.Group;
  const isIndividualProgram =
    relationship?.programVariationTypeSlug === EProgramVariation.Individual;

  const userId =
    (isGroupProgram && isGuide) || (isIndividualProgram && isTrainee)
      ? guideProfiles?.[0].userId
      : traineeProfiles?.[0].userId;

  const { settings: settingResults, isLoadingSettings } = useSettings({});
  const settings = settingResults?.at(0);

  const { timeZone: localTimezone } =
    Intl.DateTimeFormat().resolvedOptions() as {
      timeZone: TimeZone;
    };

  const currentDay = format(currentDate, 'EEEE').toLowerCase() as DayOfWeek;
  const isoDate = format(currentDate, 'yyyy-MM-dd');

  const { isLoadingUnavailabilities, unavailabilities } = useUnavailabilities({
    organizationSlug: settings?.organizationSlug,
    userId,
    date: isoDate,
    timezone: settings?.timezone,
  });
  const { defaultAvailability, availabilities, isLoadingAvailabilities } =
    useAvailabilities({
      query: isGroupProgram
        ? ''
        : `?organizationSlug=${organizationSlug}&relationshipId=${relationshipId}&userId=${userId}`,
    });
  const availability = availabilities?.at(0);

  const isLoadingSlots =
    isLoadingUnavailabilities ||
    isLoadingAvailabilities ||
    isLoadingProfiles ||
    isLoadingRelationships ||
    isLoadingSettings;

  const enabledDaysOfWeek = getEnabledDaysOfWeek(
    availability ?? (defaultAvailability as IAvailability),
  );

  const unavailableSlots = unavailabilities?.[isoDate] ?? [];
  const hasUnavailableSlots = unavailableSlots.length !== 0;

  const currentDayEnabled =
    availability?.[`${currentDay}Enabled`] ??
    defaultAvailability[`${currentDay}Enabled`];
  const currentDayStartTime =
    availability?.[`${currentDay}Start`] ??
    (defaultAvailability[`${currentDay}Start`] as string);
  const currentDayEndTime =
    availability?.[`${currentDay}End`] ??
    (defaultAvailability[`${currentDay}End`] as string);
  const timeZone = availability?.timezone ?? '';
  const outlookTimezone = findIana(timeZone)[0] ?? localTimezone;

  const [startHours, startMinutes] = currentDayStartTime.split(':');
  const [endHours, endMinutes] = currentDayEndTime.split(':');

  const localTimezoneOffset = getTimezoneOffset(localTimezone) / 60 / 60 / 1000;
  const outlookTimezoneOffset =
    getTimezoneOffset(outlookTimezone) / 60 / 60 / 1000;
  const timezoneOffset = localTimezoneOffset - outlookTimezoneOffset;

  const startHoursWithOffset = parseInt(startHours) + timezoneOffset;
  const endHoursWithOffset = parseInt(endHours) + timezoneOffset;

  const startDay = new Date(currentDate);
  startDay.setHours(startHoursWithOffset, parseInt(startMinutes), 0);

  const endDay = new Date(currentDate);
  endDay.setHours(endHoursWithOffset, parseInt(endMinutes), 0);

  const startSlots: Date[] = [];
  const endSlots: Date[] = [];

  if (currentDayEnabled) {
    if (startDay < now) {
      for (let i = startDay; i <= endDay; i = add(i, SLOT_DURATION)) {
        i >= now && startSlots.push(i);
      }
    } else {
      for (let i = startDay; i <= endDay; i = add(i, SLOT_DURATION)) {
        startSlots.push(i);
      }
    }

    startSlots.pop();
  }

  if (currentStartTime) {
    const [hours, minutes] = currentStartTime.split(':');
    const startDatetime = toDate(startDay);
    startDatetime.setHours(parseInt(hours), parseInt(minutes));

    for (
      let i = startDatetime;
      !unavailableSlots.includes(i.toString()) && i < endDay;
      i = add(i, SLOT_DURATION)
    ) {
      endSlots.push(add(i, SLOT_DURATION));
    }
  }

  const startTimeOptionsFiltered =
    enabledDaysOfWeek.length > 0
      ? startSlots.filter((slot) => {
          const slotDay = format(slot, 'EEEE').toLowerCase() as DayOfWeek;

          return enabledDaysOfWeek.includes(slotDay);
        })
      : startSlots;

  const startTimeOptions = startTimeOptionsFiltered.map((slot) => {
    const formattedStartSlot = format(slot, 'HH:mm');
    let unavailableSlot = unavailableSlots.find(
      (unavailableSlot) => formattedStartSlot === unavailableSlot,
    );

    if (unavailableSlot === undefined) {
      unavailableSlot = unavailableSlots.find(
        (unavailableSlot) =>
          unavailableSlot >= formattedStartSlot &&
          differenceInMinutes(
            new Date(`1970-01-01T${unavailableSlot}:00`),
            new Date(`1970-01-01T${formattedStartSlot}:00`),
          ) < SLOT_MINUTES,
      );
    }

    return {
      label: formattedStartSlot,
      value: formattedStartSlot,
      disabled: !!unavailableSlot,
    };
  });

  const firstUnavailableSlotAfterCurrentStartTime = unavailableSlots.find(
    (unavailableSlot) => unavailableSlot > currentStartTime,
  );
  const endTimeOptions = endSlots.map((slot) => {
    const formattedEndSlot = format(slot, 'HH:mm');

    return {
      label: formattedEndSlot,
      value: formattedEndSlot,
      disabled:
        firstUnavailableSlotAfterCurrentStartTime !== undefined &&
        formattedEndSlot > firstUnavailableSlotAfterCurrentStartTime,
    };
  });

  return {
    isLoadingSlots,
    startTimeOptions,
    endTimeOptions,
    hasUnavailableSlots,
  };
}

function getEnabledDaysOfWeek(
  availability: IAvailability | undefined,
): DayOfWeek[] {
  if (!availability) return [];

  const keys = Object.keys(availability) as (keyof IAvailability)[];
  const enabledDaysOfWeekKeys = keys.filter(
    (key) => key.endsWith('Enabled') && availability[key],
  );

  return enabledDaysOfWeekKeys.map((key) =>
    key.replace('Enabled', ''),
  ) as DayOfWeek[];
}
