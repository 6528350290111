// external
import React, { useEffect } from 'react';

import { Box, Divider } from '@mui/material';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { add, format } from 'date-fns';
import { toDate } from 'date-fns-tz';

// components
import {
  FormDatePicker,
  FormDropdownTyped,
  FormTextFieldTyped,
  ISessionInputs,
} from 'components';

// hooks
import { useLocalization, useMobileMediaQuery } from 'hooks';

// types
import { ISession } from '@guider-global/shared-types';

import { TimeAdornmentStart } from '../components';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';

interface IPastSessionEditFormProps {
  session: ISession;
  handleSubmit: () => void;
  control: Control;
  errors: FieldErrors<FieldValues>;
  setValue: UseFormSetValue<ISessionInputs>;
}

export const PastSessionEditForm: React.FC<IPastSessionEditFormProps> = ({
  session,
  handleSubmit,
  control,
  errors,
  setValue,
}) => {
  const [startTime] = useWatch({
    control,
    name: ['startTime'],
  });

  const isMobile = useMobileMediaQuery();

  //base language
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const startDay = new Date();
  startDay.setHours(9, 0, 0);

  const endDay = new Date();
  endDay.setHours(17, 30, 0);

  let nextAvailable: Date = new Date();

  if (startTime) {
    const [hours, minutes] = startTime?.toString().split(':');
    nextAvailable.setHours(parseInt(hours), parseInt(minutes), 0);
  }

  let startSlots: Date[] = [];
  let endSlots: Date[] = [];

  for (let d = startDay; d <= endDay; d = add(d, { minutes: 15 })) {
    startSlots.push(d);
  }
  for (
    let i = startTime ? nextAvailable : startDay;
    i < add(endDay, { minutes: 15 });
    i = add(i, { minutes: 15 })
  ) {
    endSlots.push(add(i, { minutes: 15 }));
  }

  const startTimeOptions = startSlots.map((slot) => {
    const formattedStartSlot = format(slot, 'HH:mm');

    return {
      label: formattedStartSlot,
      value: formattedStartSlot,
      disabled: false,
    };
  });

  const endTimeOptions = endSlots.map((slot) => {
    const formattedEndSlot = format(slot, 'HH:mm');

    return {
      label: formattedEndSlot,
      value: formattedEndSlot,
      disabled: false,
    };
  });

  useEffect(() => {
    if (startTime) {
      setValue('endTime', '');
    }
  }, [startTime, setValue]);

  return (
    <form onSubmit={handleSubmit}>
      <FormTextFieldTyped
        name="title"
        control={control}
        label={
          baseLanguage?.relationships?.sessions?.common
            ?.session_title_input_label
        }
        errors={errors}
        required
        defaultValue={session.name}
      />
      <FormDatePicker
        name="date"
        control={control}
        label={baseLanguage?.relationships?.sessions?.common?.date_input_label}
        errors={errors}
        required
        minDate={undefined}
        defaultValue={toDate(session.start)}
        maxDate={undefined}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <FormDropdownTyped
          name="startTime"
          control={control}
          label={
            baseLanguage?.relationships?.sessions?.common
              ?.start_time_input_label
          }
          errors={errors}
          required
          options={startTimeOptions}
          startAdornment={<TimeAdornmentStart isLoading={false} />}
          defaultValue={format(toDate(session.start), 'HH:mm')}
        />
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mx: 1.25, border: 'none' }}
          />
        )}
        <FormDropdownTyped
          name="endTime"
          control={control}
          label={
            baseLanguage?.relationships?.sessions?.common?.end_time_input_label
          }
          errors={errors}
          required
          options={endTimeOptions}
          startAdornment={<TimeAdornmentStart isLoading={false} />}
          defaultValue={format(toDate(session.end), 'HH:mm')}
        />
      </Box>
    </form>
  );
};

export default PastSessionEditForm;
