import { Liquid } from 'liquidjs';

export function interpolate(
  template: string | undefined,
  context: Record<string, string | undefined>,
  defaultText?: string,
) {
  try {
    if (!template) return defaultText ?? 'N/A';
    const liquid = new Liquid();
    return liquid.parseAndRenderSync(template, context);
  } catch (err) {
    console.error('Failed to interpolate string.', { template, context, err });
    return defaultText ?? 'N/A';
  }
}
