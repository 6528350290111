import { Control, useController } from 'react-hook-form';
import { SanityProgramSurveyField } from '@guider-global/shared-types';
import { TextField } from '@guider-global/ui';

export interface SurveyFormTextareaProps {
  control: Control;
  surveyField: SanityProgramSurveyField;
  isGuide: boolean;
}

export interface TextareaInputValue {
  fieldType: 'text';
  value: string;
}

export function SurveyFormTextarea({
  control,
  surveyField,
  isGuide,
}: Readonly<SurveyFormTextareaProps>) {
  const { slug, guide, trainee, field_type, text_config } = surveyField;
  const heading = isGuide
    ? guide?.guide_question_title
    : trainee?.trainee_question_title;

  const { field, fieldState } = useController<
    Record<string, TextareaInputValue>
  >({
    name: slug.current,
    control,
    rules: {
      validate: (textareaInputValue) => {
        const valueLength = textareaInputValue?.value.length;

        if (!valueLength) {
          return 'Required';
        }

        if (text_config?.max_len && valueLength > text_config?.max_len) {
          return text_config?.text.max_len_error;
        }

        if (text_config?.min_len && valueLength < text_config?.min_len) {
          return text_config?.text.min_len_error;
        }

        return true;
      },
    },
  });
  const { value: textareaInputValue, onChange } = field;
  const { error } = fieldState;

  return (
    <TextField
      label={heading}
      description={`Limited to ${text_config?.max_len} characters`}
      value={textareaInputValue?.value}
      rows={5}
      error={!!error}
      helperText={error ? error.message : text_config?.text.assistive_text}
      onChange={(event) =>
        onChange({
          fieldType: field_type,
          value: event.target.value,
        })
      }
    />
  );
}
