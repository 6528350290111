import { Control, useController } from 'react-hook-form';
import { SanityProgramSurveyField } from '@guider-global/shared-types';
import { SelectInput } from '@guider-global/ui';
import { SelectChangeEvent } from '@mui/material';

export interface SurveyFormSelectProps {
  control: Control;
  surveyField: SanityProgramSurveyField;
  isGuide: boolean;
}

export interface SelectInputValue {
  fieldType: 'select';
  value: string;
}

export function SurveyFormSelect({
  control,
  surveyField,
  isGuide,
}: Readonly<SurveyFormSelectProps>) {
  const { slug, guide, trainee, field_type, select_config } = surveyField;
  const heading = isGuide
    ? guide?.guide_question_title
    : trainee?.trainee_question_title;

  const { field, fieldState } = useController<Record<string, SelectInputValue>>(
    {
      name: slug.current,
      control,
      rules: {
        validate: (selectInputValue) => {
          if (!selectInputValue?.value) {
            return 'Required';
          }

          return true;
        },
      },
    },
  );
  const { value: selectInputValue, onChange } = field;
  const { error } = fieldState;

  const options =
    select_config?.options.map((option) => ({
      key: option._key,
      value: option.slug.current,
      label: option.option_label,
    })) ?? [];

  return (
    <SelectInput
      value={selectInputValue?.value}
      options={options}
      label={heading}
      description={select_config?.text?.assistive_text}
      error={!!error}
      errorText={error ? error?.message : ''}
      onChange={(event) =>
        onChange({
          fieldType: field_type,
          value: (event as SelectChangeEvent).target.value,
        })
      }
    />
  );
}
