import { CircularProgress, InputAdornment } from '@mui/material';
import WatchLater from '@mui/icons-material/WatchLater';

interface TimeAdornmentStartProps {
  isLoading: boolean;
}

export function TimeAdornmentStart({ isLoading }: TimeAdornmentStartProps) {
  return (
    <InputAdornment position="start">
      {isLoading ? (
        <CircularProgress sx={{ color: 'text.secondary' }} size={24} />
      ) : (
        <WatchLater />
      )}
    </InputAdornment>
  );
}
