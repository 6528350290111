import { SanityProgramSurveyField } from '@guider-global/shared-types';

export function isSurveyFieldForGuide(
  surveyField: SanityProgramSurveyField,
  sessionCount: number,
): boolean {
  return isSurveyFieldForRole('guide', surveyField, sessionCount);
}

export function isSurveyFieldForTrainee(
  surveyField: SanityProgramSurveyField,
  sessionCount: number,
): boolean {
  return isSurveyFieldForRole('trainee', surveyField, sessionCount);
}

function isSurveyFieldForRole(
  role: 'guide' | 'trainee',
  surveyField: SanityProgramSurveyField,
  sessionCount: number,
): boolean {
  const isSurveyFieldForRole =
    surveyField.audience === 'both' || surveyField.audience === role;
  const conditionalVisibility = surveyField.conditional_visibility;
  const unconditionalVisibility = !conditionalVisibility;
  const haveEnoughSessionsElapsed =
    conditionalVisibility &&
    surveyField.session_count !== undefined &&
    sessionCount >= surveyField.session_count;

  return (
    isSurveyFieldForRole &&
    (unconditionalVisibility || haveEnoughSessionsElapsed)
  );
}
