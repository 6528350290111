import { Control, useController } from 'react-hook-form';
import { SanityProgramSurveyField } from '@guider-global/shared-types';
import { RatingInput } from '@guider-global/ui';
import { useMobileMediaQuery } from 'hooks';

export interface SurveyFormRatingProps {
  control: Control;
  surveyField: SanityProgramSurveyField;
  isGuide: boolean;
}

export interface RatingInputValue {
  fieldType: 'scale' | 'extended_scale';
  value: number | string;
}

export function SurveyFormRating({
  control,
  surveyField,
  isGuide,
}: Readonly<SurveyFormRatingProps>) {
  const isMobile = useMobileMediaQuery();
  const values =
    surveyField.field_type === 'extended_scale'
      ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
      : ['1', '2', '3', '4', '5'];

  const size = surveyField.field_type === 'extended_scale' ? 'small' : 'large';
  const mobileSize =
    surveyField.field_type === 'extended_scale' ? 'xs' : 'medium';
  const { slug, guide, trainee, field_type, like_scale_labels } = surveyField;
  const heading = isGuide
    ? guide?.guide_question_title
    : trainee?.trainee_question_title;

  const { field, fieldState } = useController<Record<string, RatingInputValue>>(
    {
      name: slug.current,
      control,
      rules: {
        validate: (ratingInputValue) => {
          if (!ratingInputValue?.value) {
            return 'Required';
          }

          return true;
        },
      },
    },
  );
  const { value: ratingInputValue, onChange } = field;
  const { error } = fieldState;

  return (
    <RatingInput
      value={`${ratingInputValue?.value ?? ''}`}
      values={values}
      label={heading}
      unfavourableRatingLabel={
        like_scale_labels?.unfavourable_rating_label ?? ''
      }
      gap={isMobile ? 1 : 2}
      favourableRatingLabel={like_scale_labels?.favourable_rating_label ?? ''}
      error={!!error}
      helperText={error ? error.message : ''}
      onChange={(event, value) =>
        onChange({
          fieldType: field_type,
          value: isNaN(Number(value)) ? value : Number(value),
        })
      }
      size={isMobile ? mobileSize : size}
    />
  );
}
